.button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  transition: 0.2s;
  cursor: pointer;
  border: none;
  padding: 9px 24px;
  box-sizing: border-box;

  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: #ffffff;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 12px;
   }
  &.blue {
    background: rgb(13, 110, 241);
    &:hover {
      background: rgba(13, 110, 241, 0.7);
    }
    &:active {
    }
  }
  &.green{
      background: rgba(43, 215, 132, 1);
      &:hover{
        background: rgba(43, 215, 132, 0.7);
      }
  }
  &.red {
    background: rgba(230, 65, 65, 1);
    &:hover{
      background: rgba(230, 65, 65, 0.7);
    }
  }
  &.grey {
    background: #c7c7cc;
    pointer-events: none;
    opacity: 0.7;
  }

  &.disabled {
    background: #e5e5e5;
    &:before {
      border-color: #e5e5e5;
    }
    color: #fff;
    pointer-events: none;
  }
  &.uppercase {
    text-transform: uppercase;
  }
  &.capital {
    text-transform: capitalize;
  }
}
